"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    test_manual_exposure: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    paid_webflow: {
        variants: {
            control: { ab_test_variation: 'control' },
            webflow: {
                ab_test_variation: 'webflow'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/marketing-consultant-software'],
        webflowPath: '/p/marketing-consultant-software'
    },
    adobe_abc_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            adobeB: {
                ab_test_variation: 'adobeB'
            },
            adobeC: {
                ab_test_variation: 'adobeC'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/adobe']
    },
    referral_recipient_test: {
        variants: {
            control: { ab_test_variation: 'control' },
            recipient: {
                ab_test_variation: 'recipient',
                redirectPath: '/signup'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/invites']
    },
    public_template_recipient_funnel: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            recipient: { ab_test_variation: 'recipient' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: [/\/template[-_]preview/],
        developerLogsExposure: true
    },
    client_relationships_hero_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/client-relationships']
    },
    cta_text_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: [
            '/online-contract',
            '/get-document-signing',
            '/proposal-software',
            '/online-invoices'
        ]
    },
    new_pricing_content: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            newPricing: { ab_test_variation: 'newPricing' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/pricing']
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};
